import React from "react";
import Calendar from "@ericz1803/react-google-calendar";

const API_KEY = "AIzaSyA6fxgElEdTSHsq7DgATxEOVIdykukdrYQ";
let calendars = [
  {calendarId: "mnnt97fhtt13h8u63u31sf568k@group.calendar.google.com"}
];

let styles = {
  multiEvent: {
    backgroundColor: "#870003",
    "&:hover": {
      background: "#400001"
    }
  },
  event: {
    backgroundColor: "#870003",
    "&:hover": {
      background: "#400001"
    }
  },
  eventText: {
    color: "white"
  },
  eventCircle: {
    color: "white"
  }
}

const App = () => {
  return (
    <div>
      <Calendar styles={styles} apiKey={API_KEY} calendars={calendars} language="ES" showFooter={false} />
    </div>
  )
}

export default App;
